<template>
  <div class="pageContainer targetPage flexCloumn">
    <el-row class="searchBar flexBetween" style="flex-wrap: wrap;width:100%">
      <div class="flexStart flex_1" style="flex-wrap:wrap;">
        <el-select v-model="searchForm.dept_id" filterable clearable size="small" placeholder="请选择">
          <el-option
              v-for="item in yxList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
        <!--        <el-input placeholder="学生姓名/企业名称" v-model="searchForm.keyword" size="small" class="input-with-select"
                  style="width:200px" clearable>
                </el-input>-->
        <el-date-picker
            size="small"
            v-model="dateRangeArr"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期" style="margin-left: 10px;">
        </el-date-picker>
        <el-select v-model="searchForm.status" clearable placeholder="请选择" size="small"
                   style="width:130px;margin-left:10px">
          <el-option label="全部" value></el-option>
          <el-option label="待审核" :value="0"></el-option>
          <el-option label="审核通过" :value="1"></el-option>
          <el-option label="审核拒绝" :value="2"></el-option>
        </el-select>
        <el-button icon="el-icon-search" style="margin-left:10px" size="small" type="primary"
                   @click="() => { page.current_page = 1; getList() }">搜索
        </el-button>

        <el-button icon="el-icon-plus" style="margin-left:10px" size="small" type="primary"
                   @click="handleAdd">新增台账信息
        </el-button>
      </div>

      <div class="flexStart" style="color: #3378fa;font-size: 14px;font-weight: 700;cursor: pointer;flex-wrap: wrap;">
        <el-button v-if="$store.state.config.sync_data" icon="el-icon-refresh" size="small" type="primary"
                   @click="tongbuJava">迁移老版本
        </el-button>
      </div>

    </el-row>
    <div class="dividerBar"></div>

    <el-table v-tableFit :data="tableData" row-key="id" height="100%" size="small" stripe border
              style="width:fit-content;width:100%;">
      <el-table-column type="index" label="序号" width="55px" align="center"/>
      <el-table-column label="教师姓名" prop="username" width="100px" align="center"/>
      <el-table-column label="系部" prop="dept_name" show-overflow-tooltip width="200px" align="center"/>
      <el-table-column label="台账名称" prop="title" show-overflow-tooltip min-width="200px" align="center"/>
      <el-table-column label="创建时间" prop="cdate" show-overflow-tooltip width="200px" align="center">
        <template slot-scope="scope">{{ scope.row.cdate }}</template>
      </el-table-column>
      <el-table-column label="审核状态" prop="check_status" width="100px" align="center">
        <template slot-scope="scope">
          <el-tag size="mini" v-if="scope.row.status == 0" type="primary">待审核</el-tag>
          <el-tag size="mini" v-if="scope.row.status == 1" type="success">审核通过</el-tag>
          <el-tag size="mini" v-if="scope.row.status == 2" type="danger">审核拒绝</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="审核评语" prop="remark" show-overflow-tooltip min-width="200px"/>

      <el-table-column fixed="right" label="操作" width="250">
        <template slot-scope="scope">
          <el-tag class="pointer" @click="handleModify(scope.row,'view')" type="primary" size="mini">查看</el-tag>
          <el-tag v-if="scope.row.status == 0 && scope.row.teacher_id == $store.state.teacher.id" class="pointer"
                  @click="handleModify(scope.row,'edit')" type="primary" size="mini" style="margin-left: 5px;">编辑</el-tag>
          <el-tag class="pointer" v-if="scope.row.status === 0 && checkBtn" @click="handleModify(scope.row,'check')" type="success"
                  size="mini" style="margin-left:5px;">审批
          </el-tag>
          <el-tag v-if="scope.row.status == 0" class="pointer" @click="handleDel(scope.row)" type="danger" size="mini" style="margin-left: 5px;">删除</el-tag>
          <el-tag v-if="scope.row.status == 1" class="pointer" size="mini" style="margin-left: 5px;" @click="exportToPDF(scope.row)">导出pdf</el-tag>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @current-change="handlePageChange" @size-change="handleSizeChange" :current-page="page.current_page"
                   :page-size="page.per_page" :total="page.count" layout="total, sizes,prev, pager, next"
                   :page-sizes="[10, 20, 50, 100]" style="margin-top: 10px;">
    </el-pagination>

    <el-dialog ref="ruleForm" :visible.sync="dialogForm" :close-on-click-modal="false" @close="isRevoke = false"
               custom-class="cus_dialog" width="1000px">
      <div slot="title" class="flexBetween">
        <span style="color: #ffffff;">{{ modalTitle }}</span>
      </div>
      <el-descriptions border style="margin-top: 20px;">
        <el-descriptions-item label="用户名">{{ formData.username }}</el-descriptions-item>
        <el-descriptions-item label="所在部门">{{formData.dept_name || '-' }}</el-descriptions-item>
        <el-descriptions-item label="学年度">{{ formData.school_year }}</el-descriptions-item>
        <el-descriptions-item label="周次">
          <el-tag size="small">{{ formData.week }}</el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="开始日期">{{ formData.start_date }}</el-descriptions-item>
        <el-descriptions-item label="结束日期">{{ formData.end_date }}</el-descriptions-item>
        <el-descriptions-item label="台账名称">{{ formData.title }}</el-descriptions-item>
      </el-descriptions>
      <el-form :model="details" ref="dynamicValidateForm" label-width="90px">
        <el-divider></el-divider>
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>推荐与落实</span>
            <el-button v-if="!isView" style="float: right; padding: 3px 0" type="text" @click="addItem('tuijian')"><i class="el-icon-plus"></i> 添加</el-button>
          </div>
          <div v-for="(item, index) in details.tuijian" :key="index + 'tj'">
            <template v-if="isView">
              <span>{{item.sTime}} - {{item.eTime}}</span>
              <p>{{item.content || '-'}}</p>
              <el-divider v-if="index !== details.tuijian.length - 1"></el-divider>
            </template>
            <template v-else>
              <i v-if="index > 0" class="el-icon-remove" @click="delItem('tuijian',index)"></i>
              <el-form-item
                  :prop="'tuijian.' + index +'.dateRange'"
                  label="日期范围"
              >
                <el-date-picker
                    v-model="item.dateRange"
                    type="daterange"
                    value-format="yyyy-MM-dd"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                </el-date-picker>
              </el-form-item>
              <el-form-item
                  :prop="'tuijian.' + index + '.content'"
                  label="内容"
              >
                <el-input type="textarea" :autosize="{minRows:2}" v-model="item.content"></el-input>
                <!--          <el-button @click.prevent="">删除</el-button>-->
              </el-form-item>
            </template>
          </div>
        </el-card>
        <el-divider></el-divider>
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>学生指导与管理</span>
            <el-button v-if="!isView" style="float: right; padding: 3px 0" type="text" @click="addItem('zhidao')"><i class="el-icon-plus"></i> 添加</el-button>
          </div>
          <div v-for="(item, index) in details.zhidao" :key="index + 'zd'">
            <template v-if="isView">
              <span>{{item.sTime}} - {{item.eTime}}</span>
              <p>{{item.content || '-'}}</p>
              <el-divider v-if="index !== details.zhidao.length - 1"></el-divider>
            </template>
            <template v-else>
              <i v-if="index > 0" class="el-icon-remove" @click="delItem('zhidao',index)"></i>
              <el-form-item
                  :prop="'zhidao.' + index +'.dateRange'"
                  label="日期范围"
              >
                <el-date-picker
                    v-model="item.dateRange"
                    type="daterange"
                    value-format="yyyy-MM-dd"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                </el-date-picker>
              </el-form-item>
              <el-form-item
                  :prop="'zhidao.' + index + '.content'"
                  label="内容"
              >
                <el-input type="textarea" :autosize="{minRows:2}" v-model="item.content"></el-input>
                <!--          <el-button @click.prevent="">删除</el-button>-->
              </el-form-item>
            </template>
          </div>
        </el-card>
        <el-divider></el-divider>

        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>走访与联络</span>
            <el-button v-if="!isView" style="float: right; padding: 3px 0" type="text" @click="addItem('zoufang')"><i class="el-icon-plus"></i> 添加</el-button>
          </div>
          <div v-for="(item, index) in details.zoufang" :key="index + 'zf'">
            <template v-if="isView">
              <span>{{item.sTime}} - {{item.eTime}}</span>
              <p>{{item.content || '-'}}</p>
              <el-divider v-if="index !== details.zoufang.length - 1"></el-divider>
            </template>
            <template v-else>
              <i v-if="index > 0" class="el-icon-remove" @click="delItem('zoufang',index)"></i>
              <el-form-item
                  :prop="'zoufang.' + index +'.dateRange'"
                  label="日期范围"
              >
                <el-date-picker
                    v-model="item.dateRange"
                    type="daterange"
                    value-format="yyyy-MM-dd"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                </el-date-picker>
              </el-form-item>
              <el-form-item
                  :prop="'zoufang.' + index + '.content'"
                  label="内容"
              >
                <el-input type="textarea" :autosize="{minRows:2}" v-model="item.content"></el-input>
                <!--          <el-button @click.prevent="">删除</el-button>-->
              </el-form-item>
            </template>
          </div>
        </el-card>
        <el-divider></el-divider>

        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>其他</span>
            <el-button v-if="!isView" style="float: right; padding: 3px 0" type="text" @click="addItem('other')"><i class="el-icon-plus"></i> 添加</el-button>
          </div>
          <div v-for="(item, index) in details.other" :key="index + 'qt'">
            <template v-if="isView">
              <span>{{item.sTime}} - {{item.eTime}}</span>
              <p>{{item.content || '-'}}</p>
              <el-divider v-if="index !== details.other.length - 1"></el-divider>
            </template>
            <template v-else>
              <i v-if="index > 0" class="el-icon-remove" @click="delItem('other',index)"></i>
              <el-form-item
                  :prop="'other.' + index +'.dateRange'"
                  label="日期范围"
              >
                <el-date-picker
                    v-model="item.dateRange"
                    type="daterange"
                    value-format="yyyy-MM-dd"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                </el-date-picker>
              </el-form-item>
              <el-form-item
                  :prop="'other.' + index + '.content'"
                  label="内容"
              >
                <el-input type="textarea" :autosize="{minRows:2}" v-model="item.content"></el-input>
                <!--          <el-button @click.prevent="">删除</el-button>-->
              </el-form-item>
            </template>
          </div>
        </el-card>
        <el-divider></el-divider>

        <template v-if="!isView">
          <el-form-item
              prop="ideas"
              label="建议与设想"
          >
            <el-input type="textarea" :autosize="{minRows:2}" v-model="details.ideas"></el-input>
          </el-form-item>
        </template>
        <template v-else>
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>建议与设想</span>
            </div>
            <p>{{details.ideas || '-'}}</p>
          </el-card>
        </template>
        <el-divider></el-divider>
        <template v-if="isCheck">
          <el-form-item prop="status" label="审核状态">
            <el-radio v-model="formData.status" :label="1" >通过</el-radio>
            <el-radio v-model="formData.status" :label="2" >驳回</el-radio>
          </el-form-item>
          <el-form-item prop="remark" label="评语">
            <el-input type="textarea" :autosize="{minRows:2}" v-model="formData.remark"></el-input>
          </el-form-item>
        </template>
        <template v-else-if="isView && formData.status > 0">
          <div class="cellGroupBarTitle flexStart" style="margin-top:10px;">
            <span></span>
            <span style="font-weight: 400">审批结果</span>
          </div>
          <el-form-item label="审批结果" style="padding-top:10px;">
            <div>
              <el-tag type="success" v-if="formData.status == 1" size="small">审批通过</el-tag>
              <el-tag type="danger" v-if="formData.status == 2" size="small">审批拒绝</el-tag>
            </div>
          </el-form-item>
          <el-form-item label="评语">
            <div>
              <span style="">{{ formData.remark || '-' }}</span>
            </div>
          </el-form-item>
        </template>
        <el-form-item v-if="!isView || isCheck">
          <el-button type="primary" @click="submitForm('dynamicValidateForm')">提交</el-button>
          <el-button @click="resetForm('dynamicValidateForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import tchtree from "../com/tchTree.vue";
import moment from "moment";
import {mapState} from "vuex";

export default {
  components: {tchtree},
  data() {
    return {
      activeName: "first",
      isRevoke: false,
      searchForm: {
        keyword: "",
        status: "",
        dept_id: "",
      },
      classArr: [],
      tableData: [],
      dialogForm: false,
      modalTitle: "添加",
      details: {
        tuijian: [
          {
            sTime: '',
            eTime: '',
            content: '',
            dateRange: "",
          }
        ],
        zhidao: [
          {
            sTime: '',
            eTime: '',
            content: '',
            dateRange: "",
          }
        ],
        zoufang: [
          {
            sTime: '',
            eTime: '',
            content: '',
            dateRange: "",
          }
        ],
        other: [
          {
            sTime: '',
            eTime: '',
            content: '',
            dateRange: "",
          }
        ],
        ideas: ""
      },
      formData: {},
      sxqx: ["实习", "参军", "升学/升本", "出国", "长病假", "实习结束"],
      qyxz: [], qyxzObj: {},
      salary: [], salaryObj: {},
      page: {
        count: 0,
        current_page: 1,
        per_page: 50,
        total_page: 0
      },
      overlap: null,
      spword: "",
      sxqxArr: [], sxqxObj: {},
      applyList: [],
      dialogExportFormVisible: false,
      dialogChangeVisible: false,
      export_format: "word",
      changeList: [],
      dateRangeArr: "",
      deptInfo: {},
      yxList: [],
      isView: false,
      isCheck: false,
      checkBtn: false,
    };
  },
  mounted() {
    this.getList();
    this.$http.post('/api/teacher_dept_info').then(res => {
      let data = res.data
      this.deptInfo = data.dept || {}
      this.yxList = data.yx_list
    })
  },
  computed: {
    ...mapState(['teacher'])
  },
  watch: {
    teacher: {
      immediate: true,
      handler(val) {
        let roles = val.sys_roles
        if (roles) {
          roles.map(r => {
            if (r.role_code.indexOf('admin') >= 0 || r.role_code === 'xzr') {
              this.checkBtn = true
            }
          })
        }
      }
    }
  },
  methods: {
    handleClick(tab, event) {

      for (let item of this.applyList) {
        if ("tab" + item.id == this.activeName) {
          this.formData = item;
        }
      }
    },
    getComType() {
      return new Promise(resolve => {
        this.$http
            .post("/api/sys_param_list", {
              param_type: "'ENTER_INFO','SALARY_RANGE','SHIXI_STATUS'",
              used: 0
            })
            .then(res => {
              let qyxz = [];
              let salary = [];
              let sxqxArr = [];
              let qyxzObj = {};
              let salaryObj = {};
              let sxqxObj = {};
              for (let item of res.data) {
                if (item.param_type == "ENTER_INFO") {
                  qyxz.push(item);
                  qyxzObj[item.param_value] = item.param_desc
                }
                if (item.param_type == "SALARY_RANGE") {
                  salary.push(item);
                  salaryObj[item.param_value] = item.param_desc
                }
                if (item.param_type == "SHIXI_STATUS") {
                  sxqxArr.push(item);
                  sxqxObj[item.param_value] = item.param_desc
                }
              }
              this.qyxz = qyxz;
              this.salary = salary;
              this.sxqxArr = sxqxArr;
              this.qyxzObj = qyxzObj;
              this.salaryObj = salaryObj;
              this.sxqxObj = sxqxObj;
              console.log(qyxzObj)
              resolve();
            });
      });
    },
    handlePageChange(page) {
      this.page.current_page = page;
      this.getList();
    },
    handleSizeChange(val) {
      this.page.current_page = 1;
      this.page.per_page = val;
      this.getList()
    },
    handleAdd() {
      this.isView = false
      this.isCheck = false
      this.dialogForm = true;
      this.modalTitle = "新增台账信息";
      this.resetItem()
      this.formData = {
        school_year: new Date().getFullYear(),
        week: moment().isoWeek(),
        start_date: moment().startOf('isoWeek').format('YYYY-MM-DD'),
        end_date: moment().endOf('isoWeek').format('YYYY-MM-DD'),
        username: this.$store.state.teacher.username,
        dept_name: this.deptInfo.name,
        dept_id: this.deptInfo.xibu_id || this.deptInfo.dept_id,
      }
      this.formData.title = `${this.$store.state.teacher.username}的第${this.formData.school_year}年${this.formData.week}周工作台账`
    },
    getList() {
      let data = {};
      data.keyword = this.searchForm.keyword;
      data.status = this.searchForm.status;
      data.page = this.page.current_page;
      data.pagesize = this.page.per_page;
      data.dept_id = this.searchForm.dept_id
      data.start_date = this.dateRangeArr ? this.dateRangeArr[0] : ""
      data.end_date = this.dateRangeArr ? this.dateRangeArr[1] : ""
      this.$http.post("/api/teacher_ledger_list", data).then(res => {
        this.tableData = res.data.data;
        this.page = res.data.page;
      });
    },
    handleDel(row) {
      this.$confirm('此操作不可恢复，确定删除？', {
        type: 'warning'
      }).then(res => {
        this.$http
            .post("/api/teacher_ledger_delete", {
              id: row.id
            })
            .then(res => {
              this.$message.success("删除成功");
              this.getList();
            });
      })
    },
    handleModify(row,type) {
      if (type === 'view' || type === 'check') {
        this.isView = true
      } else {
        this.isView = false
      }
      this.isCheck = type === 'check'
      this.modalTitle = type === 'edit' ? "编辑台账信息" : type === 'view' ? '查看台账信息' : type === 'check' ? '审核台账' : '-'
      let details = JSON.parse(row.detail)
      details.tuijian.map(t => {
        t.dateRange = [t.sTime,t.eTime]
      })
      details.zhidao.map(t => {
        t.dateRange = [t.sTime,t.eTime]
      })
      details.zoufang.map(t => {
        t.dateRange = [t.sTime,t.eTime]
      })
      details.other.map(t => {
        t.dateRange = [t.sTime,t.eTime]
      })
      this.details = details
      this.formData = {...row}
      this.dialogForm = true
    },
    submitForm(st) {
      this.$refs[st].validate((valid) => {
        if (valid) {
          this.details.tuijian.map(t => {
            t.sTime = t.dateRange[0] || ""
            t.eTime = t.dateRange[1] || ""
          })
          this.details.zhidao.map(t => {
            t.sTime = t.dateRange[0] || ""
            t.eTime = t.dateRange[1] || ""
          })
          this.details.zoufang.map(t => {
            t.sTime = t.dateRange[0] || ""
            t.eTime = t.dateRange[1] || ""
          })
          this.details.other.map(t => {
            t.sTime = t.dateRange[0] || ""
            t.eTime = t.dateRange[1] || ""
          })
          this.formData.detail = JSON.stringify(this.details)
          this.$http.post(this.isCheck ? '/api/teacher_ledger_review' : '/api/teacher_ledger_edit', this.formData).then(res => {
            this.$message.success('提交成功')
            this.getList()
            this.dialogForm = false
            this.resetItem()
          })
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    uploadFiles(e) {
      this.$http
          .post("/api/import_stu_enter_xls", {
            url: e.src
          })
          .then(res => {
            this.$message.success("导入成功");
            if (res.data.errmsg && res.data.errmsg.length > 0) {
              let html = "";
              for (let msg of res.data.msg) {
                html +=
                    "<div style='font-size:12px'>" +
                    msg["姓名"] +
                    ":" +
                    msg.msg +
                    "</div>";
              }
              this.$alert(html, "未导入数据", {
                dangerouslyUseHTMLString: true
              });
            }
            this.getList();
          });
    },
    tongbuJava() {
      this.$confirm('请确保JAVA版本已存在？是否继续同步?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let loadingInstance = this.$loading({
          lock: true,
          text: "已发送请求，请稍等...",
          spinner: 'el-icon-loading'
        })
        this.$http.post("/api/sync_stu_enter", {}).then(res => {
          loadingInstance.close()
          this.$message.success('后台同步中，请刷新查看')
          this.getList();
        }).catch(err => {
          this.$message.error('同步失败')
          loadingInstance.close()
        })

      }).catch(() => {

      });
    },
    showChangeList(row) {
      this.$http.post("/api/t_sch_stu_enter_from_list", {stu_id: row.stu_id, check_status: 1}).then(res => {

        this.changeList = res.data;
        this.dialogChangeVisible = true
      })
    },
    getCls(data, id) {
      for (let item of data) {
        if (item.id === Number(id)) {
          return item
        }
        if (item.children && item.children.length) {
          return this.getCls(item.children, id)
        }
      }
    },
    clsExportExcel() {
      this.export_format = 'xls'
      this.exportWordOrPdf()
    },
    exportToPDF(row) {
      this.$http.post(this.$pdfUrl + '/exportTeacherLedger', {
        pmid: 221,
        latest: 1,
        stuArr: [{id:row.id,name:row.username}]
      }).then(res => {
        window.open(res.data.url, "_blank")
      })
    },
    addItem(type) {
      this.details[type].push({
        sTime: "",
        eTime: "",
        content: "",
        dateRange: [],
      })
    },
    delItem(type,index) {
      this.details[type].splice(index, 1)
    },
    resetItem() {
      this.details = {
        tuijian: [
          {
            sTime: '',
            eTime: '',
            content: '',
            dateRange: [],
          }
        ],
            zhidao: [
          {
            sTime: '',
            eTime: '',
            content: '',
            dateRange: [],
          }
        ],
            zoufang: [
          {
            sTime: '',
            eTime: '',
            content: '',
            dateRange: [],
          }
        ],
            other: [
          {
            sTime: '',
            eTime: '',
            content: '',
            dateRange: [],
          }
        ],
            ideas: ""
      }
    },
  }
};
</script>

<style scoped lang="less">
/deep/ .el-dialog__body {
  padding: 0px 21px;
  color: #606266;
  font-size: 14px;
  word-break: break-all;
}

/deep/ .el-tabs__item {
  font-size: 18px;
}

.dialog_right {
  .lineRow {
    .cell:first-of-type {
      width: 120px;
    }

  }
}
</style>
<style>
.cellGroupBarTitle span:nth-of-type(2) {
  color: #2469f3;
}
</style>